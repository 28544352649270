<template></template>
<script>
export default {
    props: {
        errors: Array,
        success: ""
    },

    mounted() {
        if (this.errors.length) {
            this.$toast.error(this.errors.join('\n'));
        }
        if (this.success) {
            this.$toast.success(this.success);
        }
    }
}
</script>
