<template>
    <transition name="fade">
        <div v-if="authModalForgotPassword" class="memehead-overlay">
            <div class="modal-box auth forgot-password">
                <div class="modal-close" @click="hideForgotPasswordModal">
                    <button></button>
                </div>
                <a class="position-absolute" style="top: 14px; left: 14px" @click="stepBack">
                    <icon
                        name="arrow-left"
                        width="28px"
                        height="28px"
                        color="#767676"
                    />
                </a>
                <div class="modal-container">
                    <div class="modal-title-2">Forgot password</div>
                    <p class="mt-3 mb-5 text-center">
                        Please enter your email address and we'll send you a password reset link:
                    </p>
                    <form @submit.prevent="onSubmit">
                        <div class="form-group no-label">
                            <i class="form-icon">
                                <icon
                                    name="icon-email-gray"
                                    width="24px"
                                    height="24px"
                                />
                            </i>
                            <input
                                type="text"
                                name="email"
                                v-model="email"
                                placeholder="Email"
                                class="email px-5"
                            />
                        </div>
                        <div class="form-group">
                            <button :disabled="loading" class="btn btn-blue submit-button text-uppercase">
                                <i v-if="loading" class="fa fa-circle-o-notch fa-spin"></i>
                                <span v-else>Reset Password</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import Icon from "./Icon.vue";
import _API from "../api/_api";

export default {
    components: {Icon},
    data() {
        return {
            loading: false,
            email: "",
        };
    },
    computed: {
        authModalForgotPassword() {
            return this.$store.state.authModalForgotPassword;
        }
    },

    methods: {
        hideForgotPasswordModal() {
            this.$store.commit('hideForgotPasswordModal')
        },

        showAuthModal() {
            this.$store.commit('showAuthModal')
        },

        onSubmit() {
            let API = new _API;
            this.loading = true;
            API.post('auth/password/reset', {email: this.email})
                .then((response) => {
                    this.$toast.info(response.data.responseMessage);
                    this.stepBack();
                })
                .catch((error) => {
                    if (error.response.data.responseData.data.email) {
                        this.$toast.error(error.response.data.responseData.data.email[0]);
                    }
                })
                .finally(() => this.loading = false)
        },

        stepBack() {
            this.hideForgotPasswordModal();
            this.showAuthModal();
        }
    }
}

</script>
