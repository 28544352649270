<template>
    <div>
        <div class="m-0">
            <div class="" :key="post.id" v-for="post in posts">
                <single-blog-post :post="post"/>
            </div>
        </div>
        <div id="postLoader" style="height: 10px; float: left"></div>
    </div>
</template>

<script>
import BlogAPI from "../api/BlogAPI";

export default {
    name: "Blog",
    props: {
        src: String,
    },
    data() {
        return {
            posts: [],
            now: parseInt(Math.round((new Date()).getTime() / 1000)),
            next_page_url: this.src,
            scrollObserver: null,
            isLoading: false,
            attemptedLoading: false,
            lastLoadPostCount: -1,
            firstLoadPostCount: -1,
            reactionLoading: false,
        }
    },
    computed: {
        user() {
            return this.$store.state.currentUser;
        }
    },
    mounted() {
        const scrollObserver = new IntersectionObserver(((entries, observer) => {
            if (entries[0].intersectionRatio > 0) {
                this.getPosts();
            }
        }), {
            root: null,
            rootMargin: '5px',
            threshold: 0.5
        });

        scrollObserver.observe(document.querySelector(`#postLoader`));
    },
    methods: {
        getPosts() {
            if (this.isLoading) {
                return;
            }

            if (this.attemptedLoading && this.lastLoadPostCount === 0) {
                // no more posts in this feed
                return;
            }

            this.isLoading = true;
            return BlogAPI
                .getBlog(this.next_page_url)
                .then((res) => {
                    let posts = res.data;
                    this.lastLoadPostCount = posts.length;
                    if (this.firstLoadPostCount === -1) {
                        this.firstLoadPostCount = posts.length;
                    }
                    const url = new URL(res.next_page_url);
                    this.next_page_url = this.src + url.search;
                    this.posts = this.posts.concat(posts)
                })
                .finally((f) => {
                    this.$nextTick(() => {
                        this.isLoading = false;
                        this.attemptedLoading = true;
                    })
                })
        },
    }
}
</script>
