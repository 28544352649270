<template>
    <div class="comment-reply-box">
        <form @submit.prevent="submitComment">
            <div class="row m-0">
                <div class="col p-0">
                    <input
                        v-model="newComment"
                        type="text"
                        class="form-control rounded-0"
                        :placeholder="parentCommentId ? 'write reply to thread...' : 'write reply...'"
                        ref="commentInput"
                    >
                </div>
                <div class="col-auto row m-0 reply-box-right align-items-center">
                    <div class="col text-center justify-content-center d-flex p-0">
                        <div class="upload-btn">
                            <label class="float-none mb-0">
                                <input type="file" accept="image/x-png,image/gif,image/jpeg" hidden @change="fileSelected">
                            </label>
                        </div>
                    </div>

                    <div class="col justify-content-center d-flex p-0" v-if="parentCommentId">
                        <div class="cancel-btn">
                            <button class="btn-border" type="button" @click="$emit('cancel')">CANCEL</button>
                        </div>
                    </div>

                    <div class="col justify-content-center d-flex p-0">
                        <div class="post-btn">
                            <button class="btn-rainbow" type="submit" :disabled="isLoading">
                                <i v-if="isLoading" class="fa fa-circle-o-notch fa-spin"></i>
                                <span v-else>POST</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <p class="mt-3 mb-0" v-if="newCommentFile">Selected: <strong>{{ newCommentFile.name }}</strong></p>
            <user-mention-list
                v-if="$refs.commentInput"
                :input="newComment"
                :element="$refs.commentInput"
                @userClicked="mentionClicked"
            ></user-mention-list>
        </form>
    </div>
</template>

<script>
import CommentsAPI from "../api/CommentsAPI";
import UserMentionList from "./UserMentionList";

export default {
    name: "SubmitComment",
    components: {UserMentionList},
    props: {
        postId: {
            type: String,
            required: true
        },
        parentCommentId: {
            type: String,
            default: null,
            required: false
        },
        replyToUsername: {
            type: String,
            default: null,
            required: false,
        },
    },
    data() {
        return {
            newComment: "",
            newCommentFile: null,
            isLoading: false
        }
    },
    computed: {
        user() {
            return this.$store.state.currentUser
        }
    },

    created() {
        if (this.replyToUsername) {
            this.newComment = "@" + this.replyToUsername + " ";
        }
    },

    mounted() {
        this.$refs.commentInput.focus({
            preventScroll: true
        });
    },

    methods: {
        submitComment() {
            if (!this.user) {
                this.$store.commit('showAuthModal');
                if (this.newComment || this.parentCommentId || this.newCommentFile) {
                    let commentData = {
                        'postId': this.postId,
                        'comment': this.newComment,
                        'parentCommentId': this.parentCommentId,
                        'commentFile': this.newCommentFile
                    }
                    this.$store.commit('setDraftComment', commentData);
                }
                return;
            }
            this.isLoading = true;
            CommentsAPI
                .create(this.postId, this.newComment, this.parentCommentId, this.newCommentFile)
                .then((comment) => {
                    this.$emit('submitted', comment)
                    this.newComment = "";
                    this.newCommentFile = null;
                })
                .finally(() => {
                    this.isLoading = false;
                })
        },
        fileSelected(e) {
            this.newCommentFile = e.target.files[0];
        },
        mentionClicked(u) {
            let words = this.newComment.split(" ");
            if (words.length) {
                words.pop();
            }

            words.push(`@${u.username} `);
            this.newComment = words.join(" ");
            this.$refs.commentInput.focus();
        }
    }
}
</script>
