import _API from "./_api";

let API = new _API;

const UsersAPI = {
    findUsers(query) {
        query = encodeURIComponent(query);
        return API
            .get(`search/users/${query}`)
            .then(res => res.data.responseData.data);
    },
}

export default UsersAPI;
