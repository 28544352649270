import _API from "./_api";

let API = new _API;

const TagsAPI = {
    findTags(query) {
        query = encodeURIComponent(query);
        return API
            .get(`search/tags/${query}`)
            .then(res => res.data.responseData.data);
    },

    getTrendingTags() {
        return API.get('tags/trending').then(res => res.data.responseData.data);
    }
}

export default TagsAPI;
