<template>
    <transition name="fade">
        <div v-show="commentToDelete" class="memehead-overlay">
            <div class="modal-box">
                <div class="modal-close" @click="hideModal">
                    <button></button>
                </div>
                <div class="modal-container">
                    <div class="modal-title">Are you sure you want to delete your comment?</div>
                    <div class="modal-data">
                        <div class="d-flex mt-5">
                            <button class="btn btn-blue w-100 mr-2" @click="deleteComment" :disabled="isLoading">
                                <i v-if="isLoading" class="fa fa-circle-o-notch fa-spin"></i>
                                <span v-else>Yes</span>
                            </button>
                            <button class="btn btn-danger w-100" @click="hideModal">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import CommentsAPI from "../api/CommentsAPI";

    export default {
        name: "DeleteCommentModal",
        data() {
            return {
                isLoading: false,
            }
        },
        computed: {
            commentToDelete() {
                return this.$store.state.commentToDelete
            },
        },
        methods: {
            hideModal() {
                this.$store.commit('unsetCommentToDelete')
            },
            async deleteComment() {
                this.isLoading = true;
                await CommentsAPI.delete(this.commentToDelete);
                this.isLoading = false;
                location.reload();
            }
        }
    }
</script>
