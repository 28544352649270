<template>
    <transition name="fade">
        <div v-show="commentToReport || postToReport" class="memehead-overlay report-comment-modal">
            <div class="modal-box">
                <div class="card">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item cursor-pointer d-flex align-items-center" :class="{'disabled': isLoading && item.type === reportData.report_type }" @click="handleSendReport(item)" v-for="item in reportList">
                            <i class="mr-2" :class="item.icon"></i>
                            {{item.label}}
                        </li>
                        <li class="list-group-item cursor-pointer d-flex align-items-center" @click="hideModal">
                            <i class="mr-2 close"></i>
                            Cancel
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import CommentsAPI from "../api/CommentsAPI";

    export default {
        name: "ReportModal",
        data() {
            return {
                isLoading: false,
                reportData: null,
                reportList: [
                    { type: 'abuse', label: 'Abuse', icon: 'abuse' },
                    { type: 'threats', label: 'Threats', icon: 'threats'},
                    { type: 'nudity', label: 'Nudity', icon: 'nudity' },
                    { type: 'spam', label: 'Spam', icon: 'spam' },
                    { type: 'other', label: 'Other', icon: 'other' },
                ],
            }
        },
        computed: {
            commentToReport() {
                return this.$store.state.commentToReport
            },
            postToReport() {
                return this.$store.state.postToReport
            },
            reportType() {
                if (this.commentToReport) {
                    return 'comment';
                }
                if (this.postToReport) {
                    return 'post'
                }
            },
            entityId() {
                return this.commentToReport || this.postToReport;
            }
        },
        methods: {
            hideModal() {
                this.$store.commit('unsetCommentToReport')
                this.$store.commit('unsetPostToReport')
            },

            async handleSendReport(item) {
                this.isLoading = true;
                this.reportData = {
                    entity_type: this.reportType,
                    report_type: item.type,
                    entity_id: this.entityId
                }

                return await CommentsAPI.report(this.reportData).then(() => {
                    this.isLoading = false;
                    this.hideModal();
                    this.$toast.info('Report submitted!')
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
li {
    color: #767676;
    &.disabled {
        opacity: 0.5;
    }
    i {
        width: 22px;
        height: 22px;
        opacity: 1;
    }
}

</style>
