<template>
    <button
        type="button"
        :class="{'following' : isFollowedInternal, 'btn btn-blue' : !small, 'btn-follow': small}"
        :disabled="isLoading"
        @click="toggleFollow"
    >
        <i v-show="isLoading" class="fa fa-circle-o-notch fa-spin mr-2"></i>
        <span v-show="!isLoading || (isLoading && !small)">{{isFollowedInternal ? 'Following' : 'Follow'}}</span>
    </button>
</template>

<script>
    import FollowersAPI from "../api/FollowersAPI";

    export default {
        name: "FollowButton",
        props: {
            isFollowed: Boolean,
            username: String,
            clearCache: {
                default: false,
                type: Boolean
            },
            small: {
                default: false,
                type: Boolean,
            }
        },
        data() {
            return {
                isFollowedInternal: null,
                isLoading: false
            }
        },
        computed: {
            user() {
              return this.$store.state.currentUser;
            },
            unfollowedUsers() {
                return this.$store.state.unfollowedUsers
            },
            followedUsers() {
                return this.$store.state.followedUsers
            },
        },
        watch: {
            unfollowedUsers(newVal) {
                for (let i = 0; i < this.unfollowedUsers.length; i++) {
                    if (this.unfollowedUsers[i] === this.username) {
                        this.unfollowEvent();
                        return;
                    }
                }
            },
            followedUsers(newVal) {
                for (let i = 0; i < this.followedUsers.length; i++) {
                    if (this.followedUsers[i] === this.username) {
                        if (this.isFollowedInternal) {
                            return;
                        }
                        this.isFollowedInternal = true;
                        this.$emit('update:isFollowed', true);
                        return;
                    }
                }
            }
        },
        created() {
            this.isFollowedInternal = JSON.parse(JSON.stringify(this.isFollowed))
        },
        methods: {
            toggleFollow() {
                if (!this.user) {
                    this.$store.commit('showAuthModal');
                    return;
                }

                if (this.isFollowedInternal) {
                    this.$store.commit('setUserToUnfollow', this.username)
                    this.$store.commit('showUnfollowModal')
                } else {
                    this.followRequest();
                }
            },
            followRequest() {
                this.isLoading = true;
                FollowersAPI
                    .follow(this.username, this.clearCache)
                    .then((res) => {
                        this.isFollowedInternal = true;
                        this.$emit('update:isFollowed', true);
                        this.$store.commit('addFollowedUser', this.username);
                    })
                    .finally((res) => {
                        this.isLoading = false;
                    })
            },
            unfollowEvent() {
                this.isFollowedInternal = false;
                this.$emit('update:isFollowed', false);
            }
        }
    }
</script>
