import _API from "./_api";

let API = new _API;

const CommentsAPI = {
    getPostComments(postPublicId, page) {
        return API
            .get(`posts/${postPublicId}/comments?page=${page}`)
            .then(res => res.data.responseData);
    },
    getCommentReplies(publicId) {
        return API
            .get(`comments/${publicId}/replies`)
            .then(res => res.data.responseData);
    },
    create(postPublicId, text, parentCommentPublicId = null, file = null) {
        let url = `posts/${postPublicId}/comments`;
        if (parentCommentPublicId) {
            url += "/" + parentCommentPublicId;
        }

        let formData = new FormData();
        formData.append('text', text);
        if (file) {
            formData.append('media', file);
        }

        return API
            .post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(res => res.data.responseData.data);
    },
    update(commentPublicId, comment) {
        return API.put(`comments/${commentPublicId}`, comment)
    },
    like(commentPublicId) {
        return API.post(`comments/${commentPublicId}/likes`)
    },
    dislike(commentPublicId) {
        return API.post(`comments/${commentPublicId}/dislikes`)
    },
    delete(commentPublicId) {
        return API.delete(`comments/${commentPublicId}`)
    },
    report(formData) {
        return API.post(`reports`, formData)
    }
}

export default CommentsAPI;
