export const globalMixins = {
    methods: {
        custom_asset: function (path) {
            if (process.env.NODE_ENV === 'production') {
                return `${process.env.MIX_STATIC_CONTENT_URL}/${path}`
            } else {
                return `${process.env.MIX_APP_URL}/${path}`
            }
        }
    }
}
