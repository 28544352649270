<template>
    <div class="trending-tags-wrap mt-1 mb-1">
        <a :href="tag.url" v-for="tag in trendingTags">
            <button class="btn-tag">
                <span>{{tag.name}}</span>
            </button>
        </a>
    </div>
</template>

<script>
import TagsAPI from "../api/TagsAPI";

export default {
    name: "TrendingTags",
    data: () => {
        return {
            tags: []
        }
    },
    created() {
        this.fetchTrendingTags();
    },
    computed: {
        trendingTags() {
            return this.tags;
        }
    },
    methods: {
        async fetchTrendingTags() {
            this.tags = await TagsAPI.getTrendingTags();
        }
    }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 991px) {
    .trending-tags-wrap {
        overflow-x: scroll;
        white-space: nowrap;
        margin-left: 0.5rem;

        &::-webkit-scrollbar {
            height: 3px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #4e4e4e;
            border-radius: 5px;
            &:hover {
                background: #555;
            }
        }
    }
}

</style>
