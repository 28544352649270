export default {
    state: {
        currentUser: null,
        currentPostId: null,
        currentImagePost: null,
        urlImageFromGenerator: null,

        headerMenuShown: false,
        headerNotificationsShown: false,

        authModalShown: false,
        authModalSignUp: false,
        authModalForgotPassword: false,

        addImageChallengeModalShown: false,
        submitModalShown: false,
        mobileSearchShown: false,
        unfollowModalShown: false,
        userToUnfollow: null,
        unfollowedUsers: [],
        followedUsers: [],

        postToDelete: null,
        commentToDelete: null,
        commentToReport: null,
        postToReport: null,
        draftComment: null
    },
    mutations: {
        toggleHeaderMenu(state) {
            state.headerMenuShown = !state.headerMenuShown;
            if (state.headerMenuShown) {
                state.headerNotificationsShown = false
            }
        },
        toggleHeaderNotifications(state) {
            state.headerNotificationsShown = !state.headerNotificationsShown;
            if (state.headerNotificationsShown) {
                state.headerMenuShown = false
            }
        },
        showAuthModal(state) {
            state.authModalShown = true;
            state.authModalSignUp = false;
        },
        showAuthModalSignUp(state) {
            state.authModalShown = true;
            state.authModalSignUp = true;
        },
        hideAuthModal(state) {
            state.authModalShown = false;
        },
        hideMobileSearch(state) {
            state.mobileSearchShown = false;
        },
        mobileSearchShown(state) {
            state.mobileSearchShown = !state.mobileSearchShown;
        },
        showSubmitModal(state) {
            state.submitModalShown = true;
        },
        hideSubmitModal(state) {
            state.submitModalShown = false;
        },
        showAddImageChallengeModal(state) {
            state.addImageChallengeModalShown = true;
        },
        hideAddImageChallengeModal(state) {
            state.addImageChallengeModalShown = false;
        },
        storeCurrentPostId(state, data) {
            state.currentPostId = data;
        },
        storeCurrentImagePost(state, data) {
            state.currentImagePost = data;
        },
        storeUrlImageFromGenerator(state, data) {
            state.urlImageFromGenerator = data;
        },
        storeUser(state, data) {
            state.currentUser = data;
        },
        showUnfollowModal(state) {
            state.unfollowModalShown = true;
        },
        hideUnfollowModal(state) {
            state.unfollowModalShown = false;
        },
        setUserToUnfollow(state, user) {
            state.userToUnfollow = user;
        },
        addUnfollowedUser(state, user) {
            state.unfollowedUsers.push(user);
        },
        addFollowedUser(state, user) {
            state.followedUsers.push(user);
        },
        setPostToDelete(state, postId) {
            state.postToDelete = postId;
        },
        unsetPostToDelete(state, postId) {
            state.postToDelete = null;
        },
        setDraftComment(state, data) {
            state.draftComment = data;
        },
        setCommentToDelete(state, data) {
            state.commentToDelete = data;
        },
        unsetCommentToDelete(state) {
            state.commentToDelete = null;
        },
        setCommentToReport(state, data) {
            state.commentToReport = data;
        },
        unsetCommentToReport(state) {
            state.commentToReport = null;
        },
        setPostToReport(state, data) {
            state.postToReport = data;
        },
        unsetPostToReport(state) {
            state.postToReport = null;
        },
        showForgotPasswordModal(state) {
            state.authModalForgotPassword = true;
        },
        hideForgotPasswordModal(state) {
            state.authModalForgotPassword = false;
        }
    }
}
