import _API from "./_api";

let API = new _API;


const FollowersAPI = {
    follow(username, clearCache = false) {
        return doAction('post', username, clearCache)
    },

    unfollow(username, clearCache = false) {
        return doAction('delete', username, clearCache)
    },
}

const doAction = (action, username, clearCache) => {
    let url = `users/${username}/following`;
    if (clearCache) {
        url += "?clearcache=1"
    }
    return API[action](url);
}


export default FollowersAPI;
