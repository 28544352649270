<template>
    <svg :class="className" class="icon" :style="style">
        <use :xlink:href="`#${name}`"></use>
    </svg>
</template>

<script>
const files = require.context('!svg-sprite-loader!/images', false, /.*\.svg$/)

files.keys().forEach(files)

export default {
    name: 'icon',

    props: {
        name: {
            type: String,
            required: true
        },
        className: {
            type: String,
            required: false
        },
        width: {
            type: String,
            required: false
        },
        height: {
            type: String,
            required: false
        },
        color: {
            type: String,
            required: false
        }
    },

    computed: {
        style() {
            return [this.styleWidth, this.styleHeight, this.styleColor].join(';');
        },
        styleWidth() {
            return this.width ? `width:${this.width}` : ''
        },
        styleHeight() {
            return this.height ? `height:${this.height}` : ''
        },
        styleColor() {
            return this.color ? `fill:${this.color}` : ''
        },
    },
}
</script>

<style>
.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

</style>
