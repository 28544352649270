import _API from "./_api";

let API = new _API;

const BlogAPI = {
    getBlog(src) {
        return API
            .get(`${src}`)
            .then(res => res.data);
    },
}

export default BlogAPI;
