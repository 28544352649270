<template>
    <div class="post-video">
        <video id="videoElement"
               class="w-100"
               :preload="preload"
               :poster="uploadPoster ? post.thumbnail_url : null"
               @click="updatePlayState"
               @canplay="updatePaused"
               @playing="updatePaused"
               @pause="updatePaused"
        >
            <source :src="post.source">
        </video>
        <a class="playVideo" v-show="paused" @click="play"><i class="play-icon"></i></a>
        <a class="mute d-flex justify-content-center align-items-center" v-if="muted" @click="mute"><i class="mute-icon"></i></a>
        <a class="mute d-flex justify-content-center align-items-center" v-if="!muted" @click="unmute"><i class="unmute-icon"></i></a>
    </div>
</template>

<script>
export default {
    name: "VideoHtml5",
    data() {
        return {
            videoElement: null,
            paused: null,
            muted: null
        }
    },
    props: {
        post: {
            type: Object,
            required: true
        },

        preload: {
            type: String,
            required: true
        },

        uploadPoster: {
            type: Boolean,
            required: true
        },
    },
    computed: {
        playing() {
            return !this.paused;
        }
    },
    methods: {
        updatePaused(event) {
            this.videoElement = event.target;
            this.paused = event.target.paused;
            if(this.muted === null) {
                this.videoElement.muted = !this.videoElement.muted;
                this.muted = this.videoElement.muted;
            }
            console.log(this.post);
        },
        play() {
            this.videoElement.play();
        },
        pause() {
            this.videoElement.pause();
        },
        mute() {
            this.videoElement.muted = !this.videoElement.muted;
            this.muted = !this.muted;
        },
        unmute() {
            this.videoElement.muted = !this.videoElement.muted;
            this.muted = !this.muted;
        },
        updatePlayState() {
            this.playing ? this.pause() : this.play();
        }
    }
}
</script>

<style scoped>
    .post-video {
        position:relative;
    }

    .playVideo {
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
    }

    .playVideo:hover {
        opacity: .6;
    }

    .mute {
        position:absolute;
        bottom: 12px;
        width: 30px;
        height: 30px;
        right: 12px;

    }

    .mute:hover {
        background-color: hsla(0,0%,100%,.1);
        border-radius: 30px;
    }
</style>
