<template>
    <div>
        <div v-if="!isChallenge" class="upload-box bg-transparent border-0 flex-column pb-4 pt-4 mb-3" @drop.prevent="imageDropped" @dragover.prevent>
            <label>
        <span class="upload-icon d-flex justify-content-center">
            <img class="d-block" :src="custom_asset('images/icon-upload-2.svg')" alt=""/>
        </span>
                <div class="upload-box-bottom d-flex align-items-center flex-column">
                    <h6 class="text-center text-white">Add a Post</h6>
                    <p class="pb-2 pt-2">
                        Drag and drop an image or video
                    </p>
                    <div class="file-btn mt-2 mb-2">Choose files...</div>
                    <span class="mt-2 mb-2">or</span>
                </div>
                <input type="file" hidden accept="image/*,video/*" @change="imageSelected">
            </label>

            <div class="upload-form">
                <div class="form-group m-0 d-flex pl-4 pr-4">
                    <input v-model="fileUrl" type="text" class="form-control" id="image-url"
                           placeholder="Paste image or video URL...">
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn-border full-width btn-parse-file" :disabled="isParseFileLoading" @click="parseFileUrl">
                        <i v-if="isParseFileLoading" class="fa fa-circle-o-notch fa-spin"></i>
                        <span v-else>NEXT</span>
                    </button>
                </div>
            </div>
        </div>
        <div v-if="isChallenge" class="upload-box bg-transparent border-0 flex-column pb-4 pt-4 mb-3" @drop.prevent="imageDropped" @dragover.prevent>
            <label>
        <span class="upload-icon d-flex justify-content-center mb-2">
            <img class="d-block" src="images/challenge.svg" alt=""/>
        </span>
                <div class="upload-box-bottom d-flex align-items-center flex-column">
                    <h6 class="text-center text-white">Create Challenge</h6>
                    <p class="pb-2 pt-2">
                        Drag and drop an image or video to use as the first submission of this challenge. <br>
                        <br> You’ll enter the details on the next screen.
                    </p>
                    <div class="file-btn mt-2 mb-2">Choose files...</div>
                    <span class="mt-2 mb-2">or</span>
                </div>
                <input type="file" hidden accept="image/*" @change="imageSelected">
            </label>

            <div class="upload-form">
                <div class="form-group m-0 d-flex pl-4 pr-4">
                    <input v-model="fileUrl" type="text" class="form-control" id="image-url"
                           placeholder="Paste image or video URL...">
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn-border full-width btn-parse-file" :disabled="isParseFileLoading" @click="parseFileUrl">
                        <i v-if="isParseFileLoading" class="fa fa-circle-o-notch fa-spin"></i>
                        <span v-else>NEXT</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const fileReader = new FileReader();
export default {
    name: "PostMediaUpload",

    data() {
        return {
            fileUrl: '',
        }
    },

    props: {
        isChallenge: {
            type: Boolean,
        },
        isLoading: {
            type: Boolean,
            required: true
        }
    },

    computed: {
        isParseFileLoading() {
            return this.isLoading
        }
    },

    methods: {
        imageDropped(e) {
            this.processUploadedImage(e.dataTransfer.files[0]);
        },
        imageSelected(e) {
            this.processUploadedImage(e.target.files[0]);
        },
        processUploadedImage(file) {
          return this.$emit('uploadedFile', file);
        },
        parseFileUrl() {
            return this.$emit('parseFile', this.fileUrl);
        },
    }
}
</script>

<style scoped>
    .btn-parse-file {
        width: 70px;
    }

</style>
