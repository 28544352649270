<template>
    <transition name="fade">
        <div v-show="postToDelete" class="memehead-overlay">
            <div class="modal-box">
                <div class="modal-close" @click="hideModal">
                    <button></button>
                </div>
                <div class="modal-container">
                    <div class="modal-title">Are you sure?</div>
                    <div class="modal-data">
                        You are about to delete your post. <br>
                        Are you sure that you want to proceed?
                        <div class="d-flex mt-5">
                            <button class="btn btn-danger w-100 mr-2" @click="hideModal">No</button>
                            <button class="btn btn-blue w-100" @click="deletePost" :disabled="isLoading">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import PostsAPI from "../api/PostsAPI";

    export default {
        name: "DeletePostModal",
        data() {
            return {
                isLoading: false,
            }
        },
        computed: {
            postToDelete() {
                return this.$store.state.postToDelete
            },
        },
        methods: {
            hideModal() {
                this.$store.commit('unsetPostToDelete')
            },
            async deletePost() {
                this.isLoading = true;
                await PostsAPI.deletePost(this.postToDelete);
                this.isLoading = false;
                location.reload();
            }
        }
    }
</script>
