<template>
    <div class="ellipsis-dropdown position-relative">
        <button class="btn-ellipsis" :class="{'active': showDropdown}" @click="showDropdown = !showDropdown"></button>
        <div class="dropdown-menu w-auto" :class="{'show': showDropdown}">
            <ul>
                <li v-if="canManageComment">
                    <a href="#" @click.prevent="editComment"><i class="edit"></i>Edit</a>
                </li>
                <li v-if="canManageComment">
                    <a href="#" @click.prevent="deleteComment"><i class="delete"></i>Delete</a>
                </li>
                <li v-if="canReport">
                    <a href="#" @click.prevent="reportComment"><i class="report"></i>Report</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "CommentManage",

    props: {
        comment: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            showDropdown: false
        }
    },

    created() {
        window.addEventListener('click', (e) => {
            if (!e.target.classList.contains('btn-ellipsis') && !e.target.closest('.btn-ellipsis')) {
               this.showDropdown = false
            }
        });
    },

    computed: {
        canReport() {
            return this.$store.state.currentUser ? this.$store.state.currentUser.id !== this.comment.user.id : false
        },

        canManageComment() {
            return this.$store.state.currentUser ? this.comment.user.id === this.$store.state.currentUser.id : false
        },

        showReportModal() {
            return this.$store.state.commentToReport;
        }
    },

    methods: {
        editComment() {
            this.showDropdown = false;
            return this.$emit('edit', true);
        },

        deleteComment() {
            this.showDropdown = false;
            this.$store.commit('setCommentToDelete', this.comment.public_id)
        },

        reportComment() {
            this.showDropdown = false;
            this.$store.commit('setCommentToReport', this.comment.public_id)
        }
    },
}

</script>

<style scoped>

</style>
