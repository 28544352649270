<template>
    <div class="meme-tv-container">
        <h1>meme tv</h1>
        <div class="filter-tag-row">
            <div class="filter-search-box-wrp">
                <div class="filter-search-box-title">Customize your Meme TV:</div>
                <div class="filter-search-box">
                    <multiselect
                        v-model="tags"
                        placeholder="Enter tags"
                        :options="tagOptions"
                        :multiple="true"
                        :searchable="true"
                        :loading="isLoadingTags"
                        :internal-search="false"
                        :options-limit="6"
                        :limit="10"
                        :max="10"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="true"
                        no-options="No tags found"
                        @search-change="findTag"
                    >
                    </multiselect>
                </div>
            </div>
            <div class="filter-row-right">
                <div class="filter-list-row">
                    <div class="filter-list-title">New meme every:</div>
                    <div class="filter-list">
                        <select v-model="interval" class="form-control">
                            <option v-for="o in intervalOptions" :value="o">{{o}} seconds</option>
                        </select>
                    </div>
                </div>
                <div class="filter-btn">
                    <button class="btn-rainbow full-width" @click="togglePlay">{{isPlaying ? "stop" : "play"}}</button>
                </div>
            </div>
        </div>
        <div class="memeTimerWrap">
            <div id="memeTime" class="memeTime" :class="animationClasses"></div>
        </div>
        <div class="tv-data-row">
<!--            <div class="ad-left"><img src="images/ad-1.jpg" alt=""/></div>-->
            <div class="tv-box-wrp">
                <div class="tv-box" v-if="activeMeme">
                    <div class="post-card">
                        <div class="card-top">
                            <user-info-card :user="activeMeme" />
                        </div>
                        <div class="card-title-section">
                            <div class="post-details">
                                <p class="m-0">{{activeMeme.title}}</p>
                            </div>
                            <span class="post-time">
                                <a :href="activeMeme.url" target="_blank">more info...</a>
                            </span>
                        </div>
                        <div v-if="activeMeme.type !== 2" class="post-image">
                            <a :href="activeMeme.url"><img :src="activeMeme.source" alt=""/></a>
                        </div>
                        <div v-else>
                            <div class="embed-responsive embed-responsive-16by9">
                                <iframe
                                    class="embed-responsive-item"
                                    :src="'https://www.youtube.com/embed/' + activeMeme.source "
                                >
                                </iframe>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
<!--            <div class="ad-right"><img src="images/ad-2.jpg" alt=""/></div>-->
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import {debounce} from "lodash";
    import TagsAPI from "../api/TagsAPI";
    import MemeTVAPI from "../api/MemeTVAPI";
    import UserInfoCard from "./UserInfoCard";

    let timeout = null;

    export default {
        name: "MemeTv",
        components: {UserInfoCard, Multiselect},
        props: {
            initialTags: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                interval: 5,
                intervalOptions: [3, 5, 10, 15, 20, 30],
                isPlaying: false,
                tags: JSON.parse(JSON.stringify(this.initialTags)),
                tagOptions: [],
                isLoadingTags: false,
                memes: [],
                memeIndex: 0,
                page: 1,
                activeMeme: null,
            }
        },
        computed: {
            animationClasses() {
                let data = {};
                this.intervalOptions.forEach((io) => {
                    data['memeTimeAnim' + io + "s"] = this.isPlaying && this.interval === io;
                })
                return data;
            }
        },
        watch: {
            interval() {
                if (this.isPlaying) {
                    this.togglePlay();
                }
            },
            tags() {
                if (this.isPlaying) {
                    this.togglePlay();
                }
            }
        },
        created() {
            this.togglePlay();
        },
        methods: {
            getMemes() {
                return MemeTVAPI
                    .getRandomMemesWithTag(this.tags, this.page)
                    .then((newMemes) => {
                        this.memes = this.memes.concat(newMemes);
                        if (newMemes.length) {
                            this.page++;
                        }
                    })
            },
            cycleMemes() {
                this.isPlaying = true;
                this.activeMeme = this.memes[this.memeIndex];

                this.memeIndex++;
                if (this.memes.length - this.memeIndex - 1 === 1) {
                    this.getMemes();
                }

                timeout = setTimeout(() => {
                    this.cycleMemes();
                }, this.interval * 1000)
            },
            async togglePlay() {
                this.memes = [];
                this.memeIndex = 0;
                this.page = 0;

                if (this.isPlaying) {
                    clearTimeout(timeout);
                    this.isPlaying = false;
                } else {
                    await this.getMemes();
                    this.isPlaying = true;
                    this.cycleMemes();
                }
            },
            findTag: debounce(async function (query) {
                if (query.length < 2) {
                    this.tagOptions = [];
                    return;
                }

                if (this.isLoadingTags) {
                    return;
                }

                this.isLoadingTags = true;
                this.tagOptions = await TagsAPI.findTags(query);
                this.isLoadingTags = false;
            }, 400),
        },
    }
</script>
