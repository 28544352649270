<template>
    <transition name="fade">
        <div v-show="authModalShown" class="memehead-overlay">
            <div class="modal-box auth">
                <div class="modal-close" @click="hideAuthModal">
                    <button></button>
                </div>
                <div class="modal-container">
                    <div class="modal-social-media-row">
                        <a class="social-connect-button fb-login" href="/auth/facebook">
                            <i>
                                <svg class="svg-icon icon-facebook-share" role="img" height="46" width="46"
                                     viewBox="0 0 46 46">
                                    <path
                                        d="M43.5,0H2.5C1.1,0,0,1.1,0,2.5v40.9C0,44.9,1.1,46,2.5,46h22V28.2h-6v-6.9h6v-5.1c0-5.9,3.6-9.2,8.9-9.2c2.5,0,4.7,0.2,5.4,0.3v6.2l-3.7,0c-2.9,0-3.4,1.4-3.4,3.4v4.4h6.9l-0.9,6.9h-6V46h11.7c1.4,0,2.5-1.1,2.5-2.5V2.5C46,1.1,44.9,0,43.5,0z"></path>
                                </svg>
                            </i>
                            <span class="m-auto">Continue with Facebook</span>
                        </a>

                        <a class="social-connect-button google-login" href="/auth/google">
                            <div class="content-wrapper">
                                <i>
                                    <icon
                                        name="icon-google"
                                        width="18px"
                                        height="18px"
                                    />
                                </i>
                                <span class="m-auto">Continue with Google</span>
                            </div>
                        </a>
                        <div class="break clearfix">
                            <hr>
                            <p class="or">or</p>
                            <hr>
                        </div>
                    </div>
                    <div>
                        <div v-show="!authModalSignUp">
                            <div>
                                <div>
                                    <input
                                        v-model="loginData.username"
                                        type="text"
                                        class="username"
                                        placeholder="Username or Email"
                                        name="username"
                                    >
                                    <input
                                        v-model="loginData.password"
                                        type="password"
                                        class="password"
                                        placeholder="Password"
                                        name="password"
                                    >
                                    <div class="links">
                                        <div class="clearfix">
                                            <div class="remember-me">
                                                <label>
                                                    <input type="checkbox"
                                                           name="remember"
                                                           data-bind="checked: remember">
                                                    Remember me
                                                </label>

                                            </div>
                                            <a class="forgot-password-link"
                                               @click="showForgotPasswordModal">
                                                Forgot password?
                                            </a>
                                        </div>

                                        <button type="submit" class="btn btn-blue submit-button" @click="login"
                                                :disabled="isLoading">
                                            <i v-if="isLoading" class="fa fa-circle-o-notch fa-spin"></i>
                                            <span v-else>Log In</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="clearfix">
                                <hr class="form-break">
                            </div>

                            <span class="sign-up-link"> Don't have an account?
                                <a v-on:click="showAuthModalSignUp">Create account</a>
                            </span>
                        </div>
                        <div v-show="authModalSignUp">
                            <div>
                                <div>
                                    <input
                                        v-model="registerData.email"
                                        type="text"
                                        class="email border-bottom-none border-top-none"
                                        placeholder="Your Email"
                                        name="email"
                                    >
                                </div>
                                <div>
                                    <input
                                        v-model="registerData.password"
                                        type="password"
                                        class="password"
                                        placeholder="Password"
                                        name="password"
                                    >
                                </div>
                                <div class="links">
                                    <button type="submit" class="btn btn-blue submit-button" @click="register"
                                            :disabled="isLoading">
                                        <i v-if="isLoading" class="fa fa-circle-o-notch fa-spin"></i>
                                        <span v-else>Create Account</span>
                                    </button>
                                </div>
                                <span class="tos">Creating an account means you agree with Memehead's <a target="_blank"
                                                                                                         href="/terms">Terms of Service</a> and <a
                                    target="_blank" href="/privacy">Privacy Policy</a></span>
                            </div>

                            <div class="clearfix">
                                <hr class="form-break">
                            </div>

                            <span class="sign-up-link">Have an account?
                                <a v-on:click="showAuthModal">Login now</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>

</template>

<script>
import _API from "../api/_api";
import CommentsAPI from "../api/CommentsAPI";
import Icon from "./Icon.vue";

export default {
    name: "AuthForm",
    components: {Icon},
    data() {
        return {
            isLoading: false,
            loginErrors: {},
            registerErrors: {},
            loginData: {
                email: "",
                password: ""
            },
            registerData: {
                email: "",
                password: "",
            },
        }
    },
    computed: {
        authModalSignUp() {
            return this.$store.state.authModalSignUp
        },
        authModalShown() {
            return this.$store.state.authModalShown
        },
        getDraftComment() {
            return this.$store.state.draftComment;
        },
    },
    methods: {
        showAuthModal() {
            this.$store.commit('showAuthModal')
        },
        showAuthModalSignUp() {
            this.$store.commit('showAuthModalSignUp')
        },
        showForgotPasswordModal() {
            this.hideAuthModal();
            this.$store.commit('showForgotPasswordModal');
        },
        login() {
            this.loginErrors = {};
            this.isLoading = true
            let API = new _API;
            API
                .post('auth/login', this.loginData)
                .then(res => {
                    if (this.getDraftComment) {
                        const {comment, commentFile, parentCommentId, postId} = this.getDraftComment;
                        CommentsAPI.create(postId, comment, parentCommentId, commentFile);
                    }
                    document.location.reload();
                })
                .catch((err) => {
                    this.loginErrors = err.response.data.responseData.data;
                    this.$toast.error(Object.values(this.loginErrors).map((val) => val.join('\n')).join('\n'));
                    console.log(err.response)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        register() {
            this.registerErrors = {};
            this.isLoading = true
            let API = new _API;
            API
                .post('auth/register', this.registerData)
                .then(res => {
                    document.location.href = "/";
                })
                .catch((err) => {
                    this.registerErrors = err.response.data.responseData.data;
                    this.$toast.error(Object.values(this.registerErrors).map((val) => val.join('\n')).join('\n'));
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        hideAuthModal() {
            this.$store.commit('hideAuthModal')
        }
    }
}
</script>
