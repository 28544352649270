<template>
    <div class="comment-reply-box">
        <form>
            <textarea
                ref="editCommentArea"
                type="text"
                class="form-control rounded-0 pt-2 pb-2 pl-3 pr-3"
                v-model=newCommentText
                :placeholder="comment ? 'write reply to thread...' : 'write reply...'"
            />

            <div class="controls d-flex justify-content-end p-2">
                <button @click="handleCancel" type="button" class="btn-border mr-2">CANCEL</button>
                <button @click="handleSave" type="button" class="btn-rainbow" :disabled="isLoading">
                    <i v-if="isLoading" class="fa fa-circle-o-notch fa-spin"></i>
                    <span v-else> SAVE</span>
                </button>
            </div>

            <user-mention-list
                v-if="$refs.editCommentArea"
                :input="comment.text"
                :element="$refs.editCommentArea"
                @userClicked="mentionClicked"
            >
            </user-mention-list>
        </form>
    </div>
</template>

<script>
import CommentsAPI from "../api/CommentsAPI";
import UserMentionList from "./UserMentionList";

export default {
    name: "EditComment",
    components: {UserMentionList},
    data() {
        return {
            isLoading: false,
            newCommentText: this.comment.text
        }
    },

    props: {
        comment: {
            type: Object,
            default: null,
            required: true
        }
    },


    methods: {
        handleCancel() {
            this.newCommentText = this.comment.text;
            this.$emit('cancel', true)
        },

        mentionClicked(u) {
            let words = this.comment.text.split(" ");
            if (words.length) {
                words.pop();
            }

            words.push(`@${u.username} `);
            this.comment.text = words.join(" ");
            this.$refs.editCommentArea.focus();
        },

        async handleSave() {
            this.isLoading = true;
            this.comment.text = this.newCommentText;
            this.comment.text_html = this.newCommentText;
            await CommentsAPI.update(this.comment.public_id, this.comment).then(() => {
                this.isLoading = false;
                this.handleCancel();
            });
        }
    }
}
</script>

<style scoped>

</style>
