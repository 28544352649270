import _API from "./_api";

let API = new _API;

const MemeTVAPI = {
    /** @var Array tags **/
    getRandomMemesWithTag(tags, page) {
        let param = tags.map(encodeURIComponent).join(",");
        return API
            .get(`meme-tv?tags=${param}&page=${page}`)
            .then(res => res.data.responseData.data);
    },
}

export default MemeTVAPI;
