<template>
    <div class="post-card" style="overflow: hidden;">
        <div class="blog">
            <a :href="post.url">
                <img :src="post.image" alt="">
                <p class="title m-4">{{ post.title }}</p>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "SingleBlogPost",
    props: {
        post: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>
</style>
