import axios from 'axios';
import Vue from 'vue'

export default class _API {
    constructor() {
        let service = axios.create();
            // Response interceptor
            service.interceptors.response.use(response => response, error => {
                if (error.response.status >= 500) {
                    Vue.$toast.error(`Error ${error.response.status} (${error.response.statusText})`)
                }
                return Promise.reject(error)
            })
        this.apiPrefix = "/api/";
        this.service = service;
    }
    requestSuccess(config) {
        return config;
    }

    requestError(error) {
        return Promise.reject(error);
    }

    responseSuccess(response) {
        return response;
    }

    responseError(error) {
        return Promise.reject(error);
    }

    get(url) {
        return this.service.get(this.apiPrefix + url);
    }

    post(url, data, config = {}) {
        return this.service.post(this.apiPrefix + url, data, config);
    }

    patch(url, data) {
        return this.service.patch(this.apiPrefix + url, data);
    }

    put(url, data) {
        return this.service.put(this.apiPrefix + url, data);
    }

    delete(url) {
        return this.service.delete(this.apiPrefix + url);
    }

}
