import _API from "./_api";

let API = new _API;

const NewsfeedAPI = {
    getNewsfeed(src, after, page) {
        const searchParams = new URLSearchParams({after, page});
        return API
            .get(`${src}?${searchParams.toString()}`)
            .then(res => res.data.responseData.data);
    },
}

export default NewsfeedAPI;
