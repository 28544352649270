import { render, staticRenderFns } from "./RegisterVerify.vue?vue&type=template&id=2c91b7b4&scoped=true&"
import script from "./RegisterVerify.vue?vue&type=script&lang=js&"
export * from "./RegisterVerify.vue?vue&type=script&lang=js&"
import style0 from "./RegisterVerify.vue?vue&type=style&index=0&id=2c91b7b4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c91b7b4",
  null
  
)

export default component.exports