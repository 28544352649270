<template>
    <div class="category-description-box"
         :class="{'active': this.showFullText}"
    >
        <div class="category-description-title py-1 d-lg-none">{{category.name}}</div>
        <div class="category-description">
            {{ category.description }}
        </div>
        <button class="show-more btn w-100 p-0" @click="toggleShowMore">
            <icon
                name="arrow-left"
                width="30px"
                height="30px"
                color="#767676"
            >
            </icon>
        </button>
        <div class="devider"></div>
    </div>
</template>

<script>
import Icon from "./Icon.vue";

export default {
    name: "PostMeta",
    components: {Icon},
    props: {
        category: Object
    },
    data() {
        return {
            showFullText: false
        }
    },

    methods: {
        toggleShowMore() {
            this.showFullText = !this.showFullText
        }
    }
}

</script>
